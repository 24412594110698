import React from "react"
import { Link } from "gatsby"

import { Navbar, Nav } from "react-bootstrap"

const CustomNavbar = ({ pageInfo, siteTitle }) => {
  //console.log('page_info', pageInfo)
  return (
    <>
      <Navbar variant="dark" expand="lg" id="site-navbar">
        {/* <Container> */}
        <Link to="/" className="link-no-style d-sm-block d-lg-none">
          <Navbar.Brand as="span">
            <div>{siteTitle}</div>
            <small style={{fontSize: "0.8rem"}}>{pageInfo.pageTitle}</small>
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav mx-auto" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-center mx-auto" activeKey={pageInfo && pageInfo.pageTitle}>
          <Link to="/" className="link-no-style" activeClassName="active">
              <Nav.Link as="span" eventKey="/">
                HOME
              </Nav.Link>
              </Link>
            <Link to="/new_residential" className={"link-no-style " + (pageInfo.pageName === "new_residential" ? "active" : "")} activeClassName="active">
              <Nav.Link as="span" eventKey="/new_residential">
                NEW RESIDENTIAL
              </Nav.Link>
              </Link>
            <Link to="/residential_remodels" className={"link-no-style " + (pageInfo.pageName === "residential_remodels" ? "active" : "")} activeClassName="active">
              <Nav.Link as="span" eventKey="/residential_remodels">
                RESIDENTIAL REMODELS
              </Nav.Link>
              </Link>
              <Link to="/commercial" className={"link-no-style " + (pageInfo.pageName === "commercial" ? "active" : "")} activeClassName="active">
              <Nav.Link as="span" eventKey="/commercial">
                COMMERCIAL
              </Nav.Link>
              </Link>
            <Link to="/in_design" className={"link-no-style " + (pageInfo.pageName === "in_design" ? "active" : "")} activeClassName="active">
              <Nav.Link as="span" eventKey="/in_design">
              IN DESIGN
              </Nav.Link>
              </Link>
            <Link to="/contact" className={"link-no-style " + (pageInfo.pageName === "contact" ? "active" : "")} activeClassName="active">
              <Nav.Link as="span" eventKey="/contact">
              CONTACT
              </Nav.Link>
              </Link>
          </Nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </>
  )
}

export default CustomNavbar
